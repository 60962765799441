import { CircularProgress, Typography, InputLabel, FormControl, Tooltip, Select, MenuItem, InputBase, Checkbox } from "@mui/material";
import { Grid, Box, Alert, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import MainContainer from "./MainContainer";
import { makeStyles, createStyles } from "@mui/styles";
import Common from "./screens/Common";
import Camera from "../images/camera.png";
//import BGImage from "./bgImage";
import TooltipImage from "./tooltip";
import CustomInput from "./screens/CustomInput";
import ButtonContainer from "./screens/ButtonContainer";
import CustomButton from "./screens/CustomButton";
import Vietnam from "../images/vietnam_flag.png";
import English from "../images/uk.png";
import Thailand from "../images/flag-thai.png";
import Singapore from "../images/sgp.png";
import Indonesia from "../images/id.png";
import Philippines from "../images/ph.png";
import { validateEmail, validateName, validateRequired, validator  } from "../utils/validation";
import { phoneNumberFormator } from "../utils/formatter";
import { POST_PAYMENT } from "../utils/constants";
import request from "../utils/request";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { GET_PAYMENT_PAGE_DETAILS } from "../utils/constants.js";
import SecurePayment from "../images/securePayment.png";
import ErrorOverlay from "./errorOverlay";
import { useTranslation } from "react-i18next";
import TooltipImg from "../images/info.svg";
import { display } from "@mui/system";
import PhoneImage from "./screens/phone";
import MailImage from "./screens/mail";
import parse from 'html-react-parser';
import ChaiLightIcon from "../images/portone_light.svg";
import ChaiDarkIcon from "../images/portone_dark.svg";
import { max } from "date-fns";

// const CardsImage = styled("img")(({ theme }) => ({
//   maxWidth: "50%",
//   [theme.breakpoints.down("sm")]: {
//     maxWidth: "100%",
//   },
// }));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  ".MuiSvgIcon-root":{
    width:"30px",
    height:"30px",
    marginTop:"-5px"

  },
  '& .MuiInputBase-input': {
    borderRadius: 3,
    position: 'relative',
    backgroundColor: "transparent",
    //border: '1px solid #ced4da',
    border:"none",
    fontSize: "15px",
    fontWeight:"400",
    padding:"12px 10px",
    //padding: '9px 10px 7px 9px',
    //textAlign: "center",
    //borderRight: "none",
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const Image = styled("img")(({ theme }) => ({
  width: "auto",
  height: "60px",

  [theme.breakpoints.down("sm")]: {
    width: "auto",
    height: "60px",
    //height: "100%",
  },
}));

const Dropdown = styled(Select)(({ theme }) => ({
  float: "right",
  height:"40px",
  //marginRight:"0rem",

  //padding: "5px",
  //border: "1px solid grey",
  borderRadius: "4px",
  right:"80px",
  top:"10px",
  '& .MuiSelect-select':{
    display:"flex",
    alignItems:"center",
    

  },
  '.MuiSvgIcon-root':{ width:"20px", height:"20px", color:"#707070"},
  [theme.breakpoints.down("lmd")]: {
    right:"20px",
  },

  [theme.breakpoints.down("md")]: {
    float: "right",
    padding: "5px",
    border: "1px solid grey",
    borderRadius: "4px",
    //marginRight:"1rem",
    marginTop:"20px",
    right:"0",
    marginBottom:"10px",
    '.MuiSvgIcon-root':{ width:"20px", height:"20px", color:"#707070;"},
  },
}));

// const ImageIcon = styled("img")(({ theme }) => ({
//   width: "4%",
//   height: "4%",
//   padding:"0 2px 0 2px",
//   //   marginTop: "10px",
//   [theme.breakpoints.down("sm")]: {
//     width: "4%",
//     padding:"0 2px 0 2px",
//   },
// }));



const FlagImage = styled("img")(({ theme }) => ({
  width: "20px",
  height: "15px",
  marginRight:"5px",
  border:"1px solid black",
  //   marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "20px",
    border:"1px solid black",
  },
}));


const SecureImage = styled("img")(({ theme }) => ({
  width: "20px",
  height: "25px",
  marginRight: "2px",

  [theme.breakpoints.down("sm")]: {
    width: "20px",
    height: "25px",
    marginRight: "2px",
  },
}));

// const BackgroundImage = styled("div")(({ theme }) => ({
//   backgroundImage: `url(${BackImg})`,
//   backgroundSize: "contain",
//   width: "100%",
//   height: "100vh",
//   backgroundRepeat: "no-repeat",
//   backgroundPosition: "right",
//   position: "relative",
// }));

const FormDiv = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: "5px 5px 5px 5px #00000026",
  borderRadius: "10px",
  width: "calC(100% - 80px)",
  position: "absolute",
  marginTop:"-1rem",
  left: "0%",
  padding: "2rem 3rem 2rem 2rem",

  // [theme.breakpoints.down("sm")]: {
  //   width: "80%",
  //   left: "15%",
  //   top: "20%",
  // },
  [theme.breakpoints.down("lmd")]: {
    width: "calC(100% - 40px)",
    left: "0%",
    top:"20%"
  },

  [theme.breakpoints.down("md")]: {
    width: "100%",
    left: "0%",
    top:"20%"
  },
}));

const SecureDiv = styled("div")(({ theme }) => ({
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "22px",
  textAlign: "center",
  color: "#828282",
  marginBottom: "12px",
  paddingTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    fontWeight: "500",
    fontSize: "11px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#828282",
    marginBottom: "12px",
    paddingTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const ChaiImage = styled("img")(({ theme }) => ({
  height: "25px",
  position: "relative",
  //right: "4px",
  marginLeft:"4px",
  //   marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    marginLeft:"4px"
  },
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    FormBox: {
      position: "relative",
      left: 0,

      [theme.breakpoints.up("sm")]: {
        position: "relative !important",
        top: "3rem !important",
        left: 0,
        width: "100%",
        backgroundSize: "contain",

        //overflow: "hidden",
      },
    },
    supportContainer:{
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        
      },
      langSupport:{
        display:"flex",
        alignItems:"center",

      }

    },
    langDropdown:{
      [theme.breakpoints.up("sm")]: {
        zIndex: "999999999",
      },
      [theme.breakpoints.down("sm")]: {
        //position: "absolute",
        zIndex: "999999999",
        right: "3%",
        top: "8%",
      }
    
        
      },
      contentWhole:{
        [theme.breakpoints.up("md")]: {
          paddingLeft:"80px"
        },
        [theme.breakpoints.down("lmd")]: {
          //position: "absolute",
          paddingLeft:"40px"
        },
        [theme.breakpoints.down("md")]: {
          //position: "absolute",
          paddingLeft:"10px"
        }
      
          
        },
        titleStyle:{
          [theme.breakpoints.up("md")]: {
            marginLeft:"80px"
          },
          [theme.breakpoints.down("lmd")]: {
            marginLeft:"40px"
          },
          [theme.breakpoints.down("md")]: {
            //position: "absolute",
            marginLeft:"0px",
            marginTop:"20px"
          }
        
            
          },

    // BGImage: {
    //   position: "relative",
    //   top: 0,
    //   left: 0,
    //   bottom:0,
    //   backgroundSize: "cover",
    //   width: "100%",
    //   height: "100vh",

    //   [theme.breakpoints.up("sm")]: {
    //     position: "relative",
    //     top: 0,
    //     left: 0,
    //     bottom:0,
    //   },
    // },
    outerBox:{
      height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);

const Documents = (props) => {
  const { i18n, t } = useTranslation();
  var flagA = true;
  const classes = useStyles();
  const [jwtToken, setJwtToken] = useState("");
  const [chaiPayKey, setChaiPayKey] = useState("");
  const [disable, setDisable] = useState(true);
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [phonelanguage, setPhoneLanguage] = useState("vn");
  const [initalState, setInitalStates] = useState({  
    content_details:[],
  });
  const [customerDetailsState, setCustomerDetailsState] = useState({
    fullName:"",
    email:"",
    phoneNumber:""

  });
  const [contentState, setContentState] = useState({});
  const [initalDetailsState, setDetailsInitalStates] = useState({});
  const [errors, setErrors] = useState({
    content_details:[]
  });
  const [amount, setAmountStates] = useState(0);
  const [fixedAmount, setFixedAmountStates] = useState(false);
  const [itemQuantity, setItemQuantityStates] = useState(1);
  const [datevalue, setDateValueStates] = useState();
  var amountreal;
  var loadflag = false;
  var callpostapi = true;
  var totalActiveFields = 0;
  const [dynamicMargin, setdynamicMargin] = useState(2);
  var isActiveFields = 0;
  const [isActiveFieldsCount, setIsActiveFieldsCount] = useState(0);
  const [language1, setLanguage1] = useState("en");
  const [activeContentDetails, setActiveContentDetails] = useState([]);
  var activeContentDetailsArr = [];

  // const [checked, setChecked] = useState(false);

  // console.log(checked)

  // const isError = () => {
  //   if (
  //     initalState.fullName === "" ||
  //     initalState.email === "" ||
  //     initalState.phoneNumber === "" ||
  //     initalState.amount === "undefined" ||
  //     initalState.amount === ""
  //   ) {
  //     return true;
  //   } 
  //   else if(errors.fullName === true || errors.email || errors.phoneNumber){
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // };

  var pad = function(num, totalChars) {
    var pad = '0';
    num = num + '';
    while (num.length < totalChars) {
        num = pad + num;
    }
    return num;
};
  
  function changeLanguage(e) {
    setLanguage1(e.target.value);
    i18n.changeLanguage(e.target.value);
  }

  const getpaymentdetails = (key) => {
    setLoading(true);
    return request
      .get(GET_PAYMENT_PAGE_DETAILS, key[key.length - 1])
      .then((res) => {
        if (res.status === 200) {
          flagA = false;
          setPhoneLanguage(res.data.content.country_code.toLowerCase());
          let content = res.data.payment_link_details;
          setDetailsInitalStates({
            ...content,
          });
          setContentState({
            ...res.data.content,
          });
        
          setInitalStates({...initalState, content_details: res.data.content.custom_fields.fields})
          activeContentDetailsArr = res.data.content.custom_fields.fields.filter(val => val.is_active === true);
          setActiveContentDetails(activeContentDetailsArr);
            
          let errarr = [];
          if(res.data.content.custom_fields.fields !== null){
          res.data.content.custom_fields.fields.sort((a,b)=> a.sort_order - b.sort_order).map((val) => 
            errarr.push({key: val.key, value: false})
          )}
          setAmountStates(content.amount)
          setErrors((preverror) => ({ ...preverror, content_details: errarr }))
          
          setJwtToken(res.data.payment_link_jwt_token);
          setItemQuantityStates(res.data.content.fixed_fields.minimum_quantity);
          setResponseMessage("");
          setChaiPayKey(content.chaipay_key);
          if(loadflag){
            setLoading(true);
          }
          else{
            setLoading(false)
          }
          var disflag = false;
          var activeflag = false;
          res.data.content.custom_fields.fields.length > 0 && res.data.content.custom_fields.fields.forEach((val)=> {
               if(val.is_mandatory === true){
                  disflag = true;
               }
               if(val.is_active){
                totalActiveFields += 1;
                setdynamicMargin(totalActiveFields);
               }
          })
          if(disflag === false){
            setDisable(false);
          }
          if(res.data.content.custom_fields.fields.length === 0 && res.data.content.page_type === "NORMAL"){
            if(res.data.content.fixed_fields.amount_field_type === "CUSTOM_USER_INPUT"){
              setDisable(true);
            }
            else{
              setDisable(false);
            }
          }
          else if(res.data.content.custom_fields.fields.length === 0 && res.data.content.page_type === "PREVIEW"){
            setDisable(true);
          }
          else if(res.data.content.custom_fields.fields.length > 0 && res.data.content.page_type === "NORMAL" && res.data.content.fixed_fields.amount_field_type !== "CUSTOM_USER_INPUT"){
            res.data.content.custom_fields.fields.forEach((val)=> {
                if(val.is_active && val.is_mandatory){
                    activeflag = true;
                }
            })
            if(!activeflag){
              setDisable(false);
            }
          }
          
          setLanguage1(res.data && res.data.content && res.data.content.country_code && res.data.content.country_code.toLowerCase());
          localStorage.setItem("language", res.data.content && res.data.content.currency);
          // if (content.amount !== "") {
          //   setDisable(false);
          // }
          if(res.data.content.is_dark_mode){
            document.getElementsByTagName("body")[0].style.backgroundColor = "#1A1818";
          }
          if(res.data.content.is_dark_mode){
            document.getElementsByTagName("html")[0].style.backgroundColor = "rgb(29,29,29)";
          }
          if(res.data.content.fixed_fields.amount_field_type === "CUSTOM_USER_INPUT"){
            setDetailsInitalStates((initalDetailsState) =>({...initalDetailsState, amount: null}));
          }
          res.data.content.custom_fields.fields.forEach((val)=> {
            if(val.is_active === true){
              isActiveFields += 1;
            }
          })
          setIsActiveFieldsCount(isActiveFields);
          console.log(isActiveFields);
        } else {
          setResponseMessage(res.data.message);
          setLoading(false);
        }
        return res;
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });

  };

  // const handleChange = (e) => {
  //   let { name, value } = e.target;
  //   if (value.length === 0) {
  //     setInitalStates((prevState) => ({ ...prevState, [name]: value }));
  //     setErrors((prevState) => ({ ...prevState, [name]: true }));
  //     setDisable(true);
  //   } else if (name === "fullName") {
  //     setInitalStates((prevState) => ({ ...prevState, [name]: value }));
  //     if (validateName(value)) {
  //       setErrors((prevState) => ({ ...prevState, [name]: true }));
  //       setDisable(true);
  //     } else {
  //       setErrors((prevState) => ({ ...prevState, [name]: false }));
  //     }
  //   } else if (name === "email") {
  //     setInitalStates((prevState) => ({ ...prevState, [name]: value }));
  //     if (validateEmail(value)) {
  //       setErrors((prevState) => ({ ...prevState, [name]: true }));
  //       setDisable(true);
  //     } else {
  //       setErrors((prevState) => ({ ...prevState, [name]: false }));
  //     }
  //   } else if (name === "phoneNumber") {
  //     let PhoneNumber = phoneNumberFormator(value);
  //     setInitalStates((prevState) => ({ ...prevState, [name]: PhoneNumber }));
  //     if (value.length < 10) {
  //       setErrors((prevState) => ({ ...prevState, [name]: true }));
  //       setDisable(true);
  //     } else {
  //       setErrors((prevState) => ({ ...prevState, [name]: false }));
  //     }
  //   } else {
  //     if(isError()){
  //       setDisable(true);
  //     }
  //     else{
  //       setErrors((prevState) => ({ ...prevState, [name]: false }));
  //     }
  //   }
  // };

  const handleAmount = (e) => {
    let  value = e.target.value.replace(/[^\d.]/g, "");
    let inputValue = value;
    // inputValue = inputValue.replace(/[^\d.]/g, '');
    inputValue = inputValue.replace(/^\./, '');
    inputValue = inputValue.replace(/\.(?=.*\.)/g, '');
    inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    inputValue = inputValue.replace(/(\.\d{2})\d*/, '$1')
    
    value = inputValue
    let flag1 = false;
    let flag4 = false;
    var activeflag = false;
    //console.log(error)
    if(value === ""){
      flag4 = true;
      setDisable(flag4);
    }
    else{
      errors.content_details.forEach((i) => { 
        if(i.value === true){
            flag1 = true;
        }  
      })
      setFixedAmountStates(true);
      if(contentState.custom_fields.fields !== null){
        initalState.content_details.forEach((i)=> {
          if(i.value === ""){
            flag1  = true;
          }
        })
      }
      if(flag4 || flag1){
        let f = false;
        if(flag1 && flag4){
          f = true
        }
        if(contentState.custom_fields.fields.length > 0 && contentState.page_type === "NORMAL" && contentState.fixed_fields.amount_field_type === "CUSTOM_USER_INPUT"){
          contentState.custom_fields.fields.forEach((val)=> {
              if(val.is_active && val.is_mandatory && val.value === ""){
                  activeflag = true;
              }
          })
          if(activeflag && (flag1 || flag4)){
            setDisable(true);
          }
          else{
            setDisable(false);
          }

        }
        else{
          setDisable(f);
        }
      }
      else{
        setDisable(false);
      }
    }
    if(contentState.is_stock_enabled && contentState.available_stock_quantity === 0){
      setDisable(true);
    }
    if(contentState.page_type === "PREVIEW"){
      setDisable(true);
    }
    setDetailsInitalStates({...initalDetailsState, amount: value})
  }

  const handleChange = (e, defaultname, defaultid) => {
    let value;
    let id;
    let dateval;
    if(defaultname === "datepicker"){
      //value = e;
      id = defaultid;
      var date1 = new Date(e),
      mnth = ("0" + (date1.getMonth() + 1)).slice(-2),
      day =  date1.getDate()
      if(day <10){
        day = "0"+day;}
      value = [date1.getFullYear(), mnth, day].join("-");
      dateval = e;
    }
    else if(defaultname === "dropdown"){
      value = e.target.value;
      id = defaultid;
    }else if(defaultname === "checkbox"){
      // setChecked(!checked);
      //setTimeout(() => {
        console.log(e.target.value)
        value = e.target.value === 'true' ? false : true
        id = defaultid
      //}, 0);
    }
    else{
    value = e.target.value;
    id = e.target.id;
    }
    let flag = false;
    let g = false;
    if (initalState.content_details.length) {
      let new_field = initalState.content_details;
      let new_error = errors.content_details;
      new_field.forEach((field) => {
        if (field.key == id) {
          field.value = value;
          
          //used in post api
          if(field.title === "Email"){
            setCustomerDetailsState((prevState) => ({ ...prevState, "email": value }));
          }
          else if(field.title === "Phone Number"){
            setCustomerDetailsState((prevState) => ({ ...prevState, "phoneNumber": value }));
            
          }
          else if(field.title === "Name"){
            setCustomerDetailsState((prevState) => ({ ...prevState, "fullName": value }));
          }
          let findElement = new_error.sort((a,b)=> a.sort_order - b.sort_order).map(object => object.key).indexOf(field.key);
          if(findElement === -1 || findElement === undefined){
            if(field.is_mandatory && field.type !== "datepicker"){
              new_error.push({
                key: field.key,
                value: validator(field.type, value, "d") || validateRequired(value)
              })
              
            }
            else if(!field.is_mandatory && field.type !== "datepicker"){
              new_error.push({
                key: field.key,
                value: validator(field.type, value, "p")
              })
            }
            if(field.is_mandatory && field.type === "datepicker"){
              new_error.push({
                key: field.key,
                value: validator(field.type, value, "p") || validateRequired(value)
              })
              
            }
            else if(!field.is_mandatory && field.type === "datepicker"){
              new_error.push({
                key: field.key,
                value: validator(field.type, value, dateval)
              })
            }
            
          }
          else{
            
            if(field.is_mandatory && field.type !== "datepicker"){
              new_error[findElement].value = validator(field.type, value, "d") || validateRequired(value);
              
            }
            else if(!field.is_mandatory && field.type !== "datepicker"){
              new_error[findElement].value = validator(field.type, value, "p") 
            }

            if(field.is_mandatory && field.type === "datepicker"){
              new_error[findElement].value = validator(field.type, value, "d") || validateRequired(value);
            }
            else if(!field.is_mandatory && field.type === "datepicker"){
              new_error[findElement].value = validator(field.type, value, dateval) 
            }

          }
        }

        if(field.is_mandatory && field.is_active){
          if(field.value === "" ){
            flag = true;
          }
          if(field.type === "checkbox" && (field.value === "" || field.value === false)){
            flag = true;
          }
        }
        // else if(field.field_type === "url"){
        //   if(field.value !== ""){
        //     g = true;
        //   } 
        // }

      });
      
      // new_field.forEach((i) => { 
        
      // })

      new_error.forEach((i) => { 
        if(i.value === true){
            flag = true;
        }  
      })
      
      if(!initalDetailsState.amount){
        flag = true;
      }
    
        setDisable(flag);
        // setUrlValidation(g);
        
        // setValue(1);
        // setPage(1);
      if((contentState.is_stock_enabled && contentState.available_stock_quantity === 0) || (contentState.is_stock_enabled && contentState.available_stock_quantity < contentState.fixed_fields.minimum_quantity)){
        setDisable(true);
      }
      if(contentState.page_type === "PREVIEW"){
        setDisable(true);
      }
      if(contentState.custom_fields.fields.length > 0 && contentState.page_type === "NORMAL" && contentState.fixed_fields.amount_field_type === "CUSTOM_USER_INPUT" && !fixedAmount){
        setDisable(true);
      }
      
      setInitalStates({ ...initalState, content_details: new_field });
      setErrors({ ...errors, content_details: new_error });
    }


    
    
    
    // setInitalStates((prevState) => ({ ...prevState.field_details, [name]: value }));
    
    // if (value.length === 0) {
    //   setErrors((prevState) => ({ ...prevState, [name]: true }));
    //   setDisableStep1(true);
    // } else if (name === "email" && validateEmail(value)) {
    //   setErrors((prevState) => ({ ...prevState, [name]: true }));
    //   setDisableStep1(true);
    // } else if (name === "phoneNumber" && value.length < 11) {
    //   setErrors((prevState) => ({ ...prevState, [name]: true }));
    //   setDisableStep1(true);
    // } else {
    //   setErrors((prevState) => ({ ...prevState, [name]: false }));
    //   setDisableStep1(false);
    // }
  };

  useEffect(() => {
    let key = window.location.href.split("/");
    window.addEventListener( "pageshow", function ( event ) {
      //console.log(window.performance);
      var historyTraversal = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 );
      if ( historyTraversal ) {
        window.location.reload();
      }
    });
    getpaymentdetails(key);
    //theme change
    // setLoading(true);
    // request
    //   .get(GET_PAYMENT_PAGE_DETAILS, key[key.length - 1])
    //   .then((res) => {
    //     if (res.status === 200) {
    //       let content = res.data.payment_link_details;
    //       setDetailsInitalStates({
    //         ...content,
    //       });
    //       setContentState({
    //         ...res.data.content,
    //       });
    //       setJwtToken(res.data.payment_link_jwt_token);
    //       setResponseMessage("");
    //       setLoading(false);
    //       if (content.amount !== "") {
    //         setDisable(false);
    //       }
    //     } else {
    //       setResponseMessage(res.data.message);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("error", err);
    //     setLoading(false);
    //   });
  }, []);

  let language;
  function getlangcodefromcurr(currdata) {
    if (currdata) {
      if (currdata === "VND") {
        language = "vi";
      } else if (currdata === "THB") {
        language = "th";
      }else if (currdata === "SGD") {
        language = "sgp";
      }
      else if (currdata === "IDR") {
        language = "id";
      }
      else if (currdata === "PHP") {
        language = "ph";
      }
       else {
        language = "en";
      }
    } else {
      language = "en";
    }
    return language;
  }
  var currency_symbols = {
    'USD': '$', // US Dollar
    'EUR': '€', // Euro
    'CRC': '₡', // Costa Rican Colón
    'GBP': '£', // British Pound Sterling
    'ILS': '₪', // Israeli New Sheqel
    'INR': '₹', // Indian Rupee
    'JPY': '¥', // Japanese Yen
    'KRW': '₩', // South Korean Won
    'NGN': '₦', // Nigerian Naira
    'PHP': '₱', // Philippine Peso
    'PLN': 'zł', // Polish Zloty
    'PYG': '₲', // Paraguayan Guarani
    'THB': '฿', // Thai Baht
    'UAH': '₴', // Ukrainian Hryvnia
    'VND': '₫', // Vietnamese Dong
    'SGD': 'S$',
    'IDR': 'Rp',
    'AUD': 'A$',
    'HKD': 'HK$',
};
  var choosenLanguage = "en";
  var formattedValue;
  function getFormattedNumber(numberValue, currencyValue) {
    choosenLanguage = getlangcodefromcurr(currencyValue);
    if(contentState.fixed_fields.amount_field_type === "CUSTOM_USER_INPUT"){
      numberValue = numberValue.toString().replace(/,/g, '')
    }
    formattedValue = new Intl.NumberFormat(choosenLanguage, {
      style: "currency",
      currency: currencyValue,
      minimumFractionDigits: 2,
    }).format(numberValue);
    return formattedValue;
  }

  const handlePay = () => {
    let key = window.location.href.split("/");
    let custom_page_field = {};
    if(contentState.custom_fields.fields !== null){
      initalState.content_details.forEach((item)=>{
        
        // let {key, value} = item;
        // var pair = {[key]: value};
        if(item.type === "phone_number"){
          custom_page_field[item.key] = item.value.replace(/\s/g,'').replace("-", "").replace(/[!@#$%^&*()-]/g, '');
        }else if(item.type === 'checkbox'){
          custom_page_field[item.key] = item.value === true ? 'true' : 'false';
        }
        else{
          custom_page_field[item.key]= item.value;
        }
      })
    }
    let chosen_payment_methods = [];
    if(contentState.chosen_payment_methods !== null){
      contentState.chosen_payment_methods.forEach((item, index)=>{
        chosen_payment_methods[index]= item;
      })
    }
    let reqbody = {
       ...initalDetailsState,
      amount: contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY" ? parseFloat(parseFloat((initalDetailsState.amount)*itemQuantity).toFixed(2)) : contentState.fixed_fields.amount_field_type === "CUSTOM_USER_INPUT" ? parseFloat(parseFloat(initalDetailsState.amount.replace(/,/g, '')).toFixed(2)) : parseFloat(parseFloat(initalDetailsState.amount).toFixed(2)),
      title: contentState.title,

      chosen_payment_methods: [
        ...chosen_payment_methods
      ],

      customer_details: {
        name: customerDetailsState.fullName,
        phone_number: `${customerDetailsState.phoneNumber.replace(/\s/g,'').replace("-", "")}`,
        email_address: customerDetailsState.email,
      },
      billing_details: {
        billing_address : {
          address_ref : "",
          city: "City",
          country_code: contentState.country_code,
          country_name: 'country',
          locale: contentState.country_code,
          line_1: 'address',
          line_2: 'address2',
          postal_code: "000000",
          state: "state"

        },
        billing_name: customerDetailsState.fullName,
        billing_phone: `${customerDetailsState.phoneNumber.replace(/\s/g,'').replace("-", "")}`,
        billing_email: customerDetailsState.email,
      },
      shipping_details: {
        shipping_address : {
          address_ref : "",
          city: "City",
          country_code: contentState.country_code,
          locale: contentState.country_code,
          line_1: 'address',
          line_2: 'address2',
          postal_code: "000000",
          state: "state"

        },
        shipping_name: customerDetailsState.fullName,
        shipping_phone: `${customerDetailsState.phoneNumber.replace(/\s/g,'').replace("-", "")}`,
        shipping_email: customerDetailsState.email,
      },
      custom_page_fields:{
        ...custom_page_field 
        
      },
      source: "payment_page",
      stock_quantity : itemQuantity,
      unit_amount : contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY" ? parseFloat(((initalDetailsState.amount)*itemQuantity)/itemQuantity.toFixed(2)) : parseFloat((contentState.amount/itemQuantity).toFixed(2)),
    };
    loadflag = true
    getpaymentdetails(key).then((resp) => { 
      // console.log("jkk", resp)
      if(resp.data.content.fixed_fields['amount_field_type'] === "FIXED_WITH_QUANTITY" && resp.data.content.fixed_fields.minimum_quantity > resp.data.content.available_stock_quantity && resp.data.content.is_stock_enabled){
        callpostapi = false;
        if(alert("Stock is Low, Reload Required.")){}
          else window.location.reload(); 
      }
      else if(resp.data.content.amount !== contentState.amount && resp.data.content.fixed_fields['amount_field_type'] !== "CUSTOM_USER_INPUT"){
        callpostapi = false;
        if(alert("Amount has been updated, Reload Required.")){}
        else window.location.reload(); 
      }
      else if((resp.data.content && (resp.data.content.fixed_fields['minimum_amount'] !== contentState.fixed_fields.minimum_amount || resp.data.content.fixed_fields['maximum_amount'] !== contentState.fixed_fields.maximum_amount)) && (resp.data.content.fixed_fields['amount_field_type'] === "CUSTOM_USER_INPUT")){
        callpostapi = false;
        if(alert("Amount has been updated, Reload Required.")){}
        else window.location.reload(); 
      }
      callpostapi && request
      .post(POST_PAYMENT, reqbody, jwtToken, chaiPayKey)
      .then((res) => {
        // if(initalDetailsState.amount < contentState.fixed_fields.minimum_amount || initalDetailsState.amount > contentState.fixed_fields.maximum_amount){
        //   setResponseMessage("Amount should not be greater than "+ `${contentState.fixed_fields.maximum_amount}` + " and should not less than " + `${contentState.fixed_fields.minimum_amount}`)
        // }
        //else{
          if (res.status === 201) {
            setResponseMessage("");
            window.location.assign(res.data.payment_link);
          } else {
            if(res.status === 401 && res.data.status_code === "4054"){
              window.location.reload();
            }
            if(contentState.fixed_fields.amount_field_type === "CUSTOM_USER_INPUT"){
              setDisable(true)
            }
            setLoading(false);
            setResponseMessage(res.data.message);
          }
        //}
      })
      .catch((err) => {
        if(err.status === 401){
          let key = window.location.href.split("/");
          getpaymentdetails(key);
          handlePay();

        }
        console.log("Error", err);
      });
    })
  };

  return (
    <>
    <div style={ contentState.is_dark_mode ? { width: "100%",
    height: "15px",
    marginBottom: "4%",
    position:"absolute",
    backgroundColor: `${
      initalDetailsState &&
      initalDetailsState.merchant_details &&
      initalDetailsState.merchant_details.brand_primary_color
        ? initalDetailsState.merchant_details.brand_primary_color
        : "black"
    }`
    } : { }}>

      { window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ].length === 0 || contentState.is_active === false  ? (
        <ErrorOverlay active={contentState.is_active} />
      ) : (
        ""
      )}
      {loading ? <div className={classes.outerBox}> <CircularProgress size={40}/></div> :
      <div
        style={ 
          
        {
          width: "100%",
          height: "15px",
          marginBottom:"4%",
          backgroundColor: `${
            initalDetailsState &&
            initalDetailsState.merchant_details &&
            initalDetailsState.merchant_details.brand_primary_color
              ? initalDetailsState.merchant_details.brand_primary_color
              : "black"
          }`}
       

      }
      >
        <div style={contentState.is_dark_mode ? {backgroundColor: "rgb(29,29,29)", position:"relative", zIndex:"-999"}:{backgroundColor: "#F5F5F5", position:"relative", zIndex:"-999"}}>
        <MainContainer>
          {responseMessage !== "" && (
            <Alert
              severity="error"
              onClose={() => {
                setResponseMessage("");
              }}
              sx={{ position:"absolute", top:"0px", width:"100%"}}
            >
              {responseMessage}
            </Alert>
          )}
          <Grid  xs={12} sm={12} alignItems={"left"} marginBottom="35px">
          <div className={classes.titleStyle} style={{ display: "inline-block"}}>
            <div style={{ display:"flex", alignItems:"center"}}>
          <Image
            src={
              contentState &&
              contentState.merchant_details &&
              contentState.merchant_details.logo
                ? contentState.merchant_details.logo
                : Camera
            }
            alt={
              contentState &&
              contentState.merchant_details &&
              contentState.merchant_details.name
            }
          />
          <Typography
            variant="h4"
            component="h4"
            style={
              contentState.is_dark_mode ?
              {
                justifyContent: "left",
                color: "#C1C1C1",
                marginLeft:"10px",
                borderLeft: "1px solid #C4C4C4",
                height: "34px",
                paddingLeft:"8px"
              }
              :
              
              {
             
              color: "#090909",
              marginLeft:"10px",
              borderLeft: "1px solid #C4C4C4",
              height: "34px",
              paddingLeft:"8px"
            }}
          >
            {" "}
            {contentState &&
              contentState.merchant_details &&
              contentState.merchant_details.name}
          </Typography>
          </div>
          </div>
          
        





          <div style={{ float: "right"}}>
          <span className={classes.langDropdown}>
            <FormControl fullWidth style={contentState.is_dark_mode ?
              { display: "flex", alignItems: "center", justifyContent: "center", borderRadius:"4px"} : {display: "flex", alignItems: "center", justifyContent: "center", border:"1px solid #FFFFFF4D", borderRadius:"4px"}}>
            <Dropdown id="mySelect" value={language1 ? (language1 === "en" || language1 === "vn" || language1 === "th" || language1 === "sg" || language1 === "id" || language1 === "ph") ? language1 : "en" : "en"} onChange={changeLanguage} style={ contentState.is_dark_mode ? (isActiveFieldsCount <= 4 && window.innerWidth >= 1000 ? {right:"80px", color: "#FFFFFF", border:"1px solid #FFFFFF4D"} : {color: "#FFFFFF", border:"1px solid #FFFFFF4D"}):(isActiveFieldsCount <= 4 && window.innerWidth >= 1000 ? {right:"80px"} : {}) }>
                <MenuItem divider={true} className="langSupport" value="en" ><FlagImage src={English} alt="ind" /> English
                  </MenuItem>
                  <MenuItem divider={true} className="langSupport" value="vn"> <FlagImage src={Vietnam} alt="viet" />Tiếng Việt
                  </MenuItem>
                  <MenuItem divider={true} className="langSupport" value="th"> <FlagImage src={Thailand} alt="thai" /> ไทย
                  </MenuItem>
                  <MenuItem divider={true} className="langSupport" value="sg"> <FlagImage src={Singapore} alt="singapore" /> Singapore
                  </MenuItem>
                  <MenuItem divider={true} className="langSupport" value="id"> <FlagImage src={Indonesia} alt="indonesia" /> bahasa Indonesia
                  </MenuItem>
                  <MenuItem divider={true} className="langSupport" value="ph"> <FlagImage src={Philippines} alt="philippines" /> pilipino
                  </MenuItem>
            </Dropdown>
            </FormControl>
          </span>
          </div>
        </Grid>

          <Grid container className={classes.contentWhole} style={ contentState.is_dark_mode ? {backgroundColor: "rgb(29,29,29)"}:{backgroundColor:"#FFFFFF"}}>
            <Grid
              item
              xs={12}
              md={5}
              justifyContent="left"
              //order={{ xs: 2, md: 1 }}
            >
              <Common
                initalDetailsState={initalDetailsState}
                contentState={contentState}
              />
            </Grid>
            
            <Grid
                item
                xs={12}
                md={initalState.content_details !== null &&
                  isActiveFieldsCount <= 4 ? 6: 7}
                justifyContent="right"
                //order={{ xs: 1, md: 2 }}
              > 
              {/* <BackgroundImage> */}

              <Box className={classes.FormBox}>
                {/* <BGImage
                  className={classes.BGImage}
                  // color={
                  //   initalDetailsState.merchant_details &&
                  //   initalDetailsState.merchant_details.brand_primary_color
                  // }
                  color= {contentState.is_dark_mode ? "#1D1D1D" : "#D9D9D9"}
                /> */}
                
                <div className="ParentCardDiv">
                <FormDiv className={classes.FormDiv} style={ contentState.is_dark_mode ? (isActiveFieldsCount <= 4 && window.innerWidth >= 1000 ?  {left:"16%", backgroundColor: "#1D1D1D"} :{backgroundColor: "#1D1D1D"}): (isActiveFieldsCount <= 4 && window.innerWidth >= 1000 ? {left:"16%"}: {})}>
                  <div style={ !contentState.is_dark_mode ? {
                      position: "absolute",
                      backgroundColor: `${
                        initalDetailsState &&
                        initalDetailsState.merchant_details && initalDetailsState.merchant_details.brand_secondary_color ?initalDetailsState.merchant_details.brand_secondary_color : "#ffffff" }`,
                      width: "100%",
                      height: "100%",
                      left: "0px",
                      top: "0px",
                      opacity: "0.1" }:
                      {}
                    }
                  ></div>
                  {(contentState.is_stock_enabled && contentState.available_stock_quantity === 0) || (contentState.is_stock_enabled && contentState.available_stock_quantity < contentState.fixed_fields.minimum_quantity) ? 
                  <h4 style={{ color:"red", marginBottom:"1rem"}}>Sorry Stock Quantity is {contentState.available_stock_quantity}!!!</h4> 
                  :
                  <></>
                  }
                
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{
                      justifyContent: "left",
                      fontSize:"18px",
                      color: `${
                        initalDetailsState &&
                        initalDetailsState.merchant_details &&
                        initalDetailsState.merchant_details.brand_primary_color
                          ? initalDetailsState.merchant_details
                              .brand_primary_color
                          : "black"
                      }`,
                    }}
                  >
                    {t("Payment Details")}
                  </Typography>
                  {
                  initalState.content_details !== null ? 
                  isActiveFieldsCount <= 4 || window.innerWidth <= 768 ? 
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                    {contentState.fixed_fields && (contentState.fixed_fields.amount_field_type === "FIXED" || contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY") ?
                  <InputLabel shrink htmlFor="bootstrap-input" style={contentState.is_dark_mode ? { fontSize: "18px", marginTop:"12px", color:"#707070", fontWeight:"700", lineHeight:"26px"} :{fontSize: "18px",  marginTop:"12px", fontWeight:"700", color:"rgba(0,0,0,0.75)", lineHeight:"26px"}}>
                      Amount <span style={{ color: "red" }}> * </span>
                  </InputLabel>
                  :""}
                  
                  
                  <div className="parentDiv" style={ contentState.fixed_fields && (contentState.fixed_fields.amount_field_type === "FIXED" || contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY")?  contentState.is_dark_mode ? { display: "flex", alignItems:"center", justifyContent:"space-between", height: "43px", borderRadius: "5px" , marginBottom: "15px", border:"1px solid #FFFFFF1A"} : {display: "flex", alignItems:"center", justifyContent:"space-between", height: "43px", borderRadius: "5px" , marginBottom: "15px", border:"1px solid #0024644D"}: {}}>
                  <div className="firstChildDiv" style={{ fontSize:"1.3rem"}}>
                    
                        

                  {contentState.fixed_fields && (contentState.fixed_fields.amount_field_type === "FIXED" || contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY") ?
                  

                    <FormControl variant="standard" fullWidth>
                      <BootstrapInput
                        id="amount"
                        //fullWidth
                        sx={ contentState.is_dark_mode ? { m: 0, width: '10ch',color:"#FFFFFF" } :{m: 0, width: '10ch' }}
                        variant="standard"
                        placeholder="Amount"
                        type="text"
                        name="amount"
                        value={
                          //initalDetailsState.stock_quantity !== 0 ?
                          initalDetailsState.amount
                            ? flagA === true && itemQuantity !== 0 ?
                             getFormattedNumber(
                                initalDetailsState.amount*itemQuantity,
                                initalDetailsState.currency
                              )
                              :
                              getFormattedNumber(
                                initalDetailsState.amount,
                                initalDetailsState.currency
                              )
                            :
                            "" 
                            //: ""
                          }
                        //onChange={handleAmount}
                        //onBlur={onBlur}
                        //onFocus={onFocus}
                        readOnly={true}
                        //disabled={true}
                        // error={initalDetailsState.amount == "" 
                        //   //|| initalDetailsState.stock_quantity === 0
                        //    ? true : false}
                        // errorText={
                        //     initalState.amount === ""
                        //       ? "Required"
                        //       : "Invalid Amount"
                        // }
                        required={true}
                        inputProps={{ maxLength: 30, min: 1 }}
                        // helperText={(error && errorText)}
                        style={{ outline:"none", width: "fit-content" }}
                      />
                      
                      <FormHelperText style={ initalDetailsState.amount ?  { display: "none"} :   {color: "red", fontSize: "12px"} }>
                        {initalDetailsState.amount 
                          //|| initalDetailsState.stock_quantity === 0 
                        ? false : true && initalState.amount === ""
                        ? "Required"
                        : "Invalid Amount"}
                      </FormHelperText>
                      
                    </FormControl>
                  :
                  <CustomInput
                    id="amount"
                    isdark={contentState.is_dark_mode}
                    variant="standard"
                    type="text"
                    name="amount"
                    placeholder="Enter Amount here"
                    //value="Rs. 2500"
                    label="Amount"
                    maxLength={30}
                    tooltip=""
                    required={true}
                    min={1}
                    onkeyup={() => initalDetailsState.amount <= 0 ? setDetailsInitalStates({...initalDetailsState, amount: contentState.fixed_fields.minimum_amount})  : initalDetailsState.amount}
                    //disabled={true}
                    onChange={handleAmount}
                    value={initalDetailsState.amount ? currency_symbols[initalDetailsState.currency] !== undefined ?  `${currency_symbols[initalDetailsState.currency]}`+" " + initalDetailsState.amount : initalDetailsState.amount : ""}
                    error={initalDetailsState.amount === undefined || (String(initalDetailsState.amount).replace(",", "") <= contentState.fixed_fields.maximum_amount && String(initalDetailsState.amount).replace(",", "") >= contentState.fixed_fields.minimum_amount) || initalDetailsState.amount === null
                    //|| initalDetailsState.stock_quantity === 0 
                    ? false : true}
                    errorText={
                      initalDetailsState.amount === undefined || initalDetailsState.amount === null || initalDetailsState.amount === ""
                        ? "Invalid Amount"
                        : `The amount must be between ${currency_symbols[initalDetailsState.currency]} ${contentState?.fixed_fields?.minimum_amount} and ${currency_symbols[initalDetailsState.currency]} ${contentState?.fixed_fields?.maximum_amount}`
                    }
                    />
                  }
                  </div>


                  <div className="secondChildDiv" style={{ display: "flex", alignItems: "center", justifyContent: "end", width: "40%", marginRight:"1rem"}}>

                  {contentState.fixed_fields && contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY" ? 
                      <>
                      <button onClick={(e)=> {
                          flagA = false;
                          let stock_quantity = initalDetailsState.stock_quantity;
                          let flag3 = false;
                          let flag2 = false;
                          if(contentState.fixed_fields  && contentState.available_stock_quantity < itemQuantity && contentState.is_stock_enabled){
                            alert("Sorry Stock is not available. There are "+ itemQuantity + " quantity available only.")
                          }
                          else{
                            setDetailsInitalStates({...initalDetailsState, stock_quantity: itemQuantity > contentState.fixed_fields.minimum_quantity ? setItemQuantityStates(itemQuantity-1) : itemQuantity, amount: itemQuantity > contentState.fixed_fields.minimum_quantity ? (amount/(itemQuantity-1))*(itemQuantity-1) : (amount/itemQuantity)*(itemQuantity)})
                          }
                          if(stock_quantity === 0){
                            flag3 = true;
                            setDisable(flag3);
                          }
                          else{
                            errors.content_details.forEach((i) => {
                              if(i.value === true){
                                  flag2 = true;
                              }
                            })
                            if(flag3 || flag2){
                              setDisable(flag2);
                            }
                          }
                        }} className="btn btn-primary" style={ itemQuantity === contentState.fixed_fields.minimum_quantity ? contentState.is_dark_mode ? { fontSize:"12px" , height: "24px",
                        width: "23px", border: "1px solid #0024644D", backgroundColor:"#FFFFFF1A", borderRadius:"5px", padding:"0 0", position:"relative"} : { fontSize:"12px" , height: "24px",
                        width: "23px", border: "1px solid #0024644D", backgroundColor:"#cccccc", borderRadius:"5px", padding:"0 0", position:"relative"}  :  contentState.is_dark_mode ? {fontSize:"12px", height: "24px",
                        width: "23px", border: "1px solid #FFFFFF1A", borderRadius:"5px", backgroundColor:"transparent", padding:"0 0", position:"relative"} :
                        {fontSize:"12px", height: "24px",
                        width: "23px", border: "1px solid #0024644D", borderRadius:"5px", backgroundColor:"transparent", padding:"0 0", position:"relative"}}><b style={ contentState.is_dark_mode ? { color:"#FFFF", fontSize:"1rem"} :{fontSize:"1rem"}}>&#8722; </b></button>
                      
                      <BootstrapInput
                        id="quantity"
                        sx={ contentState.is_dark_mode ? { m: 0, width: '8ch', color:"#ffffff", border:"1px solid #FFFFFF1A !important" } :{m: 0, width: '8ch'}}
                        variant="standard"
                        placeholder="Quantity"
                        type="text"
                        name="quantity"
                        value={itemQuantity}
                        onChange={(e)=>{
                          setDetailsInitalStates({...initalDetailsState, stock_quantity:e.target.value})
                            
                        }}
                        disabled={false}
                        required={true}
                        readOnly={true}
                        inputProps={{ maxLength: 30, max: 10, min: 0 }}
                        // helperText={(error && errorText)}
                        style={{ outline:"none", fontSize:13, borderRadius:"5px", border:"1px solid #0024644D", margin:"0px 5px", height:"24px" }}
                      />
                      <button onClick={(e)=> {
                        flagA = false;
                        let stock_quantity = initalDetailsState.stock_quantity;
                        //setDetailsInitalStates({...initalDetailsState, stock_quantity: stock_quantity !== 0 ? stock_quantity : initalDetailsState.stock_quantity+1});
                        //stock_quantity = initalDetailsState.stock_quantity;
                        if(contentState.fixed_fields  && contentState.available_stock_quantity <= itemQuantity && contentState.is_stock_enabled){
                          alert("Sorry Stock is not available. There are "+ itemQuantity + " quantity available only.")
                        }
                        else{
                        setDetailsInitalStates({...initalDetailsState, stock_quantity: itemQuantity < contentState.fixed_fields.maximum_quantity ? setItemQuantityStates(itemQuantity+1) : itemQuantity, amount: itemQuantity < contentState.fixed_fields.maximum_quantity ? (amount/(itemQuantity+1))*(itemQuantity+1) : (amount/itemQuantity)*(itemQuantity)})
                        }
                        
                        }} className="btn btn-primary" style={ itemQuantity === contentState.fixed_fields.maximum_quantity ? contentState.is_dark_mode ? { fontSize:"12px" , height: "24px",
                        width: "23px", border: "1px solid #0024644D", backgroundColor:"#FFFFFF1A", borderRadius:"5px", padding:"0 0", position:"relative"} : { fontSize:"12px" , height: "24px",
                        width: "23px", border: "1px solid #0024644D", backgroundColor:"#cccccc", borderRadius:"5px", padding:"0 0", position:"relative"} : contentState.is_dark_mode ? { fontSize:"12px" , height: "24px", padding:"0 0",
                        width: "23px", border: "1px solid #FFFFFF1A", borderRadius:"5px", backgroundColor:"transparent", position:"relative"}: 
                        {fontSize:"12px", height: "24px",
                        width: "23px", border: "1px solid #0024644D", borderRadius:"5px", backgroundColor:"transparent", padding:"0 0", position:"relative"} }><b style={contentState.is_dark_mode ? { color:"#FFFF", fontSize:"1rem"} :{fontSize:"1rem"}}> &#65291; </b></button>
                        
                      </>
                      
                    
                    : ""
                  }
                  </div>
                  </div>
                  

                  {/* <button onClick={(e)=> {
                    let stock_quantity = initalDetailsState.stock_quantity;
                    setDetailsInitalStates({...initalDetailsState, stock_quantity:initalDetailsState.stock_quantity > 0 ? initalDetailsState.stock_quantity-1 : initalDetailsState.stock_quantity, amount: stock_quantity > 0 ? amount*(stock_quantity-1) : amount*(stock_quantity)})
                  }} class="btn btn-primary">-</button>
                  <CustomInput
                    id="quantity"
                    variant="standard"
                    type="number"
                    name="quantity"
                    placeholder="Quantity"
                    //value="Rs. 2500"
                    label="Quantity"
                    maxLength={30}
                    max={10}
                    min={contentState.fixed_fields && contentState.fixed_fields.minimum_quantity}
                    tooltip=""
                    required={true}
                    //disabled={true}
                    readOnly={true}
                    value={initalDetailsState.stock_quantity}
                    onChange={(e)=>{
                      setDetailsInitalStates({...initalDetailsState, stock_quantity:e.target.value})
                        
                    }}
                  />
                  <button onClick={(e)=> {
                    let stock_quantity = initalDetailsState.stock_quantity;
                    //setDetailsInitalStates({...initalDetailsState, stock_quantity: stock_quantity !== 0 ? stock_quantity : initalDetailsState.stock_quantity+1});
                    //stock_quantity = initalDetailsState.stock_quantity;
                    setDetailsInitalStates({...initalDetailsState, stock_quantity: initalDetailsState.stock_quantity < 10 ? initalDetailsState.stock_quantity+1 : initalDetailsState.stock_quantity, amount: amount*(stock_quantity+1)})
                  }} class="btn btn-primary">+</button> */}
                  {
                  initalState.content_details !== null ? 
                  initalState.content_details.length > 0 && activeContentDetails.length > 0 ? 

                  activeContentDetails.sort((a,b)=> a.sort_order - b.sort_order)
                  .map((val, index)=>{
                  return(
                    
      
                  val.type === "phone_number" && val.is_active ? 
                  <>
                  <div>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    style={ contentState.is_dark_mode ? { fontSize: "18px",  marginTop:"12px", color:"#707070", fontWeight:"700", position:"relative", whiteSpace:'pre-line'}:{fontSize: "18px",  marginTop:"12px", fontWeight:"700", color:"rgba(0,0,0,0.75)", position:"relative", whiteSpace:"pre-line"}}
                  >
                    {val.title} <span style={{ color: "red" }}> {val.is_mandatory ? "*" : ""} </span>
                    
                    { val.tooltip !== "" ?
                    <span style={{ marginTop: "5px", marginLeft:"2px"}}>
                      <Tooltip title={val.tooltip}>
                          {/* <img src={TooltipImg} alt={"info icon"} width="15px"/>  */}
                          <span>
                          <TooltipImage color = {contentState.is_dark_mode ? "#707070" : "#fff"} />
                          </span>
                      </Tooltip>
                    </span>
                    : ""
                   }
                  
                  </InputLabel>
                  
                  <PhoneInput
                    id={val.key}
                    inputProps={{ id: val.key}}
                    country={phonelanguage}
                    name="phoneNumber"
                    placeholder="Phone Number"
                    countryCodeEditable={false}
                    // value={formatPhoneNumber(initalState.phoneNumber)}
                    preferredCountries={["vn", "th", "sg", "in", "ph", "id", "my"]}
                    autoFormat={true}
                    inputStyle={contentState.is_dark_mode ? {
                      height: "42px",
                      width: "100%",
                      fontSize:"13px",
                      fontWeight: "500",
                      border:"1px solid #FFFFFF1A",
                      backgroundColor: "transparent",
                      color:"#ffffff"
                    } :{
                      height: "42px",
                      width: "100%",
                      fontSize:"13px",
                      fontWeight: "500",
                      border:"1px solid #0024644D",

                      backgroundColor: "transparent",
                    }}
                    // countryCodeEditable={false}
                    //   inputProps={{
                    //     value: `${initalState.phoneNumber}`,
                    //   }}
                    onChange={(value, data, event, formattedValue) => handleChange(event)}
                    // onChange={(value, data, event, formattedValue) => {
                    //   if (value.length < 10) {
                    //     setErrors({ ...errors, phoneNumber: true });
                    //     //setDisable(true);
                    //   } 
                    //   else{
                    //     //setDisable(false);
                    //     setErrors({ ...errors, phoneNumber: false });
                    //   }
                    //   setInitalStates({
                    //     ...initalState,
                    //     phoneNumber: `${value}`,
                    //   });
                      
                    //}}
                  />
                  </div>
                  <FormHelperText style={{ color: "red", fontSize: "12px" }}>
                    {errors.content_details[index].value && "Invalid Phone Number"}
                  </FormHelperText>
                  </>
                  :
                  val.drop_down_options && (val.drop_down_options.options !== null && val.is_active  ? 
                  val.drop_down_options.options.length > 0 && val.type === "dropdown" ?
                  
                    <FormControl size="small" fullWidth variant="standard" sx={{ margin: "12px 0px 0px 0px"}}>
                      <div>
                      <InputLabel shrink htmlFor="bootstrap-input" style={ contentState.is_dark_mode ? { fontSize: "18px",  color:"#707070", fontWeight:"700", position:"relative", whiteSpace:"pre-line"}:{fontSize: "18px",  fontWeight:"700", color:"rgba(0,0,0,0.75)", position:"relative", whiteSpace:"pre-line"}}>
                      {val.title} <span style={{ color: "red" }}> {val.is_mandatory ? "*" : ""} </span> 
                      {val.tooltip !== "" ? 
                        <span style={{ marginTop: "5px", marginLeft:"2px"}}>
                          <Tooltip title={val.tooltip}>
                            {/* <img src={TooltipImg} alt={"info icon"} width="15px" />  */}
                            <span>
                            <TooltipImage color = {contentState.is_dark_mode ? "#707070" : "#fff"} />
                            </span>
                        </Tooltip>
                        </span>
                        : ""
                      }
                      </InputLabel>
                      </div>
                      <Select
                        style={contentState.is_dark_mode ? { height: "42px", borderRadius:"5px", color:"#ffffff", border:"1px solid #FFFFFF1A" } : {height: "42px", border:"1px solid #0024644D", borderRadius:"5px"}}
                        labelId="demo-simple-select-required-label"
                        id={val.key}
                        name={val.key}
                        required={val.is_mandatory}
                        value={val.value}
                        label={val.display_title }
                        onChange={(value) => handleChange(value, "dropdown", val.key)}
                        input={<BootstrapInput />
                         }
                         sx={contentState.is_dark_mode ? {'.MuiSvgIcon-root':{ width:"20px", height:"20px", color:"#FFFFFF1A"}, '& .MuiInputBase-input': { "&:focus": { boxShadow:"none"}}}: {'& .MuiInputBase-input': { "&:focus": { boxShadow:"none"}}}}
                      >
                        <MenuItem value="">-----------</MenuItem>
                        {val.drop_down_options.options.map((option) => 
                        <MenuItem value={option.value}>{option.name}</MenuItem>
                        )}
                        
                
                      </Select>
                    </FormControl>
                    
                  :
                  val.type==="datepicker" && val.is_active ? 
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    isdark={contentState.is_dark_mode}
                    //placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    //onFocus="black"
                    value={val.value === "" ? null : val.value }
                    onChange={(value) => {
                      value===null ? 
                      handleChange("", "datepicker", val.key)
                      :
                      handleChange(value, "datepicker", val.key)
                    }}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid date"
                    }
                  />
                  :
                  val.type==="email" && val.is_active ? 
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    isdark={contentState.is_dark_mode}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    //onFocus="black"
                    value={val.value}
                    onChange={handleChange}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid Email"
                    }
                  />
                  : 
                  val.is_active ?
                    val.type !== "checkbox" ?
                    <CustomInput
                      id={val.key}
                      variant="outlined"
                      type={val.type}
                      name={val.key}
                      placeholder={val.description}
                      label={val.title}
                      maxLength={256}
                      required={val.is_mandatory}
                      tooltip={val.tooltip}
                      isdark={contentState.is_dark_mode}
                      //onFocus="black"
                      value={val.value}
                      onChange={handleChange}
                      error={errors.content_details[index].value}
                      errorText={
                        initalState.content_details[index].value === ""
                          ? "Required"
                          : "Invalid "+`${val.title}`
                      }
                    />
                    :
                    <div style={window.innerWidth > 767 ? { marginTop: "10px", display: "flex", alignItems:"center", marginTop:"20px" } : {  display: "flex", alignItems:"center", marginTop:"20px" }}>
                      <Checkbox checked={val.value} value={val.value} onChange={(e) => handleChange(e, "checkbox", val.key)} />
                      <InputLabel htmlFor="bootstrap-input" style={ contentState.is_dark_mode ? {  color:"#707070", fontSize:"16px", textOverflow:"initial", whiteSpace:"normal"}: { fontSize:"16px", color:"rgba(0,0,0,0.75)", textOverflow:"initial", whiteSpace:"normal"}}> {val.description} 
                      <span style={ val.is_mandatory ? { color: "red" }: contentState.is_dark_mode ? {color:"transparent"} : {color: "transparent"}}> {"*"} </span> 
                      { val.tooltip !== "" ?
                        (<span style={{ marginLeft:"2px",  marginTop:"2px"}}>
                          <Tooltip title={val.tooltip}>
                              <span>
                              <TooltipImage color = {contentState.is_dark_mode ? "#707070" : "#fff"} size = "12" />
                              </span>
                          </Tooltip>
                        </span>)
                        : ""
                      }
                       </InputLabel>
                    </div>
                  :""
                  : 
                  val.is_active ?
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    isdark={contentState.is_dark_mode}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    //onFocus="black"
                    value={val.value}
                    onChange={handleChange}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid "+`${val.title}`
                    }
                  />
                  :""
                  ))
                
                  })
                
                  : ""
                  : ""
                  }




                    </Grid>

                  </Grid>
                :

                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                  {contentState.fixed_fields && (contentState.fixed_fields.amount_field_type === "FIXED" || contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY") ?
                  <InputLabel shrink htmlFor="bootstrap-input" style={contentState.is_dark_mode ? { fontSize: "18px",  marginTop:"12px", color:"#707070", fontWeight:"700", lineHeight:"26px"} :{fontSize: "18px", marginTop:"12px", fontWeight:"700", color:"rgba(0,0,0,0.75)", lineHeight:"26px"}}>
                      Amount <span style={{ color: "red" }}> * </span>
                  </InputLabel>
                  :""}
                  
                  
                  <div className="parentDiv" style={ contentState.fixed_fields && (contentState.fixed_fields.amount_field_type === "FIXED" || contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY")?  contentState.is_dark_mode ? { display: "flex", alignItems:"center", justifyContent:"space-between", height: "43px", borderRadius: "5px" , marginBottom: "15px", border:"1px solid #FFFFFF1A"} : {display: "flex", alignItems:"center", justifyContent:"space-between", height: "43px", borderRadius: "5px" , border:"1px solid #0024644D"}: {}}>
                  <div className="firstChildDiv" style={{ fontSize:"1.3rem"}}>
                    
                        

                  {contentState.fixed_fields && (contentState.fixed_fields.amount_field_type === "FIXED" || contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY") ?
                  

                    <FormControl variant="standard" fullWidth>
                      <BootstrapInput
                        id="amount"
                        //fullWidth
                        sx={ contentState.is_dark_mode ? { m: 0, width: '10ch',color:"#FFFFFF" } :{m: 0, width: '10ch' }}
                        variant="standard"
                        placeholder="Amount"
                        type="text"
                        name="amount"
                        value={
                          //initalDetailsState.stock_quantity !== 0 ?
                          initalDetailsState.amount
                            ? flagA === true && itemQuantity !== 0 ?
                             getFormattedNumber(
                                initalDetailsState.amount*itemQuantity,
                                initalDetailsState.currency
                              )
                              :
                              getFormattedNumber(
                                initalDetailsState.amount,
                                initalDetailsState.currency
                              )
                            :
                            "" 
                            //: ""
                          }
                        //onChange={handleAmount}
                        //onBlur={onBlur}
                        //onFocus={onFocus}
                        readOnly={true}
                        //disabled={true}
                        // error={initalDetailsState.amount == "" 
                        //   //|| initalDetailsState.stock_quantity === 0
                        //    ? true : false}
                        // errorText={
                        //     initalState.amount === ""
                        //       ? "Required"
                        //       : "Invalid Amount"
                        // }
                        required={true}
                        inputProps={{ maxLength: 30, min: 1 }}
                        // helperText={(error && errorText)}
                        style={{ outline:"none", width: "fit-content" }}
                      />
                      
                      <FormHelperText style={ initalDetailsState.amount ?  { display: "none"} :   {color: "red", fontSize: "12px"} }>
                        {initalDetailsState.amount 
                          //|| initalDetailsState.stock_quantity === 0 
                        ? false : true && initalState.amount === ""
                        ? "Required"
                        : "Invalid Amount"}
                      </FormHelperText>
                      
                    </FormControl>
                  :
                  <CustomInput
                    id="amount"
                    isdark={contentState.is_dark_mode}
                    variant="standard"
                    type="text"
                    name="amount"
                    placeholder="Enter Amount here"
                    //value="Rs. 2500"
                    label="Amount"
                    maxLength={30}
                    tooltip=""
                    required={true}
                    min={1}
                    onkeyup={() => initalDetailsState.amount <= 0 ? setDetailsInitalStates({...initalDetailsState, amount: contentState.fixed_fields.minimum_amount})  : initalDetailsState.amount}
                    //disabled={true}
                    onChange={handleAmount}
                    value={initalDetailsState.amount ? currency_symbols[initalDetailsState.currency] !== undefined ?  `${currency_symbols[initalDetailsState.currency]}`+" " + initalDetailsState.amount : initalDetailsState.amount : ""}
                    error={initalDetailsState.amount === undefined || (String(initalDetailsState.amount).replace(",", "") <= contentState.fixed_fields.maximum_amount && String(initalDetailsState.amount).replace(",", "") >= contentState.fixed_fields.minimum_amount) || initalDetailsState.amount === null
                    //|| initalDetailsState.stock_quantity === 0 
                    ? false : true}
                    errorText={
                      initalDetailsState.amount === undefined || initalDetailsState.amount === null || initalDetailsState.amount === ""
                        ? "Invalid Amount"
                        : `The amount must be between ${currency_symbols[initalDetailsState.currency]} ${contentState?.fixed_fields?.minimum_amount} and ${currency_symbols[initalDetailsState.currency]} ${contentState?.fixed_fields?.maximum_amount}`
                    }
                    />
                  }
                  </div>


                  <div className="secondChildDiv" style={{ display: "flex", alignItems: "center", justifyContent: "end", width: "40%", marginRight:"1rem"}}>

                  {contentState.fixed_fields && contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY" ? 
                      <>
                      <button onClick={(e)=> {
                          flagA = false;
                          let stock_quantity = initalDetailsState.stock_quantity;
                          let flag3 = false;
                          let flag2 = false;
                          if(contentState.fixed_fields  && contentState.available_stock_quantity < itemQuantity && contentState.is_stock_enabled){
                            alert("Sorry Stock is not available. There are "+ itemQuantity + " quantity available only.")
                          }
                          else{
                            setDetailsInitalStates({...initalDetailsState, stock_quantity: itemQuantity > contentState.fixed_fields.minimum_quantity ? setItemQuantityStates(itemQuantity-1) : itemQuantity, amount: itemQuantity > contentState.fixed_fields.minimum_quantity ? (amount/(itemQuantity-1))*(itemQuantity-1) : (amount/itemQuantity)*(itemQuantity)})
                          }
                          if(stock_quantity === 0){
                            flag3 = true;
                            setDisable(flag3);
                          }
                          else{
                            errors.content_details.forEach((i) => {
                              if(i.value === true){
                                  flag2 = true;
                              }
                            })
                            if(flag3 || flag2){
                              setDisable(flag2);
                            }
                          }
                        }} className="btn btn-primary" style={ itemQuantity === contentState.fixed_fields.minimum_quantity ? contentState.is_dark_mode ? {fontSize:"12px" , height: "24px",
                        width: "23px", border: "1px solid #0024644D", backgroundColor:"#FFFFFF1A", borderRadius:"5px", padding:"0 0", position:"relative"} : { fontSize:"12px" , height: "24px",
                        width: "23px", border: "1px solid #0024644D", backgroundColor:"#cccccc", borderRadius:"5px", padding:"0 0", position:"relative"}  :  contentState.is_dark_mode ? {fontSize:"12px", height: "24px",
                        width: "23px", border: "1px solid #FFFFFF1A", borderRadius:"5px", backgroundColor:"transparent", padding:"0 0", position:"relative"} :
                        {fontSize:"12px", height: "24px",
                        width: "23px", border: "1px solid #0024644D", borderRadius:"5px", backgroundColor:"transparent", padding:"0 0", position:"relative"}}><b style={ contentState.is_dark_mode ? { color:"#FFFF", fontSize:"1rem"} :{fontSize:"1rem"}}>&#8722; </b></button>
                      
                      <BootstrapInput
                        id="quantity"
                        sx={ contentState.is_dark_mode ? { m: 0, width: '8ch', color:"#ffffff", border:"1px solid #FFFFFF1A !important" } :{m: 0, width: '8ch'}}
                        variant="standard"
                        placeholder="Quantity"
                        type="text"
                        name="quantity"
                        value={itemQuantity}
                        onChange={(e)=>{
                          setDetailsInitalStates({...initalDetailsState, stock_quantity:e.target.value})
                            
                        }}
                        disabled={false}
                        required={true}
                        readOnly={true}
                        inputProps={{ maxLength: 30, max: 10, min: 0 }}
                        // helperText={(error && errorText)}
                        style={{ outline:"none", fontSize:13, borderRadius:"5px", border:"1px solid #0024644D", margin:"0px 5px", height:"24px" }}
                      />
                      <button onClick={(e)=> {
                        flagA = false;
                        let stock_quantity = initalDetailsState.stock_quantity;
                        //setDetailsInitalStates({...initalDetailsState, stock_quantity: stock_quantity !== 0 ? stock_quantity : initalDetailsState.stock_quantity+1});
                        //stock_quantity = initalDetailsState.stock_quantity;
                        if(contentState.fixed_fields  && contentState.available_stock_quantity <= itemQuantity && contentState.is_stock_enabled){
                          alert("Sorry Stock is not available. There are "+ itemQuantity + " quantity available only.")
                        }
                        else{
                        setDetailsInitalStates({...initalDetailsState, stock_quantity: itemQuantity < contentState.fixed_fields.maximum_quantity ? setItemQuantityStates(itemQuantity+1) : itemQuantity, amount: itemQuantity < contentState.fixed_fields.maximum_quantity ? (amount/(itemQuantity+1))*(itemQuantity+1) : (amount/itemQuantity)*(itemQuantity)})
                        }
                        
                        }} className="btn btn-primary" style={ itemQuantity === contentState.fixed_fields.maximum_quantity ? contentState.is_dark_mode ? { fontSize:"12px" , height: "24px",
                        width: "23px", border: "1px solid #0024644D", backgroundColor:"#FFFFFF1A", borderRadius:"5px", padding:"0 0", position:"relative"} : { fontSize:"12px" , height: "24px",
                        width: "23px", border: "1px solid #0024644D", backgroundColor:"#cccccc", borderRadius:"5px", padding:"0 0", position:"relative"} : contentState.is_dark_mode ? { fontSize:"12px" , height: "24px", padding:"0 0",
                        width: "23px", border: "1px solid #FFFFFF1A", borderRadius:"5px", backgroundColor:"transparent", position:"relative"}: 
                        {fontSize:"12px", height: "24px",
                        width: "23px", border: "1px solid #0024644D", borderRadius:"5px", backgroundColor:"transparent", padding:"0 0", position:"relative"} }><b style={contentState.is_dark_mode ? { color:"#FFFF", fontSize:"1rem"} :{fontSize:"1rem"}}> &#65291; </b></button>
                        
                      </>
                      
                    
                    : ""
                  }
                  </div>
                  </div>
                  

                  {/* <button onClick={(e)=> {
                    let stock_quantity = initalDetailsState.stock_quantity;
                    setDetailsInitalStates({...initalDetailsState, stock_quantity:initalDetailsState.stock_quantity > 0 ? initalDetailsState.stock_quantity-1 : initalDetailsState.stock_quantity, amount: stock_quantity > 0 ? amount*(stock_quantity-1) : amount*(stock_quantity)})
                  }} class="btn btn-primary">-</button>
                  <CustomInput
                    id="quantity"
                    variant="standard"
                    type="number"
                    name="quantity"
                    placeholder="Quantity"
                    //value="Rs. 2500"
                    label="Quantity"
                    maxLength={30}
                    max={10}
                    min={contentState.fixed_fields && contentState.fixed_fields.minimum_quantity}
                    tooltip=""
                    required={true}
                    //disabled={true}
                    readOnly={true}
                    value={initalDetailsState.stock_quantity}
                    onChange={(e)=>{
                      setDetailsInitalStates({...initalDetailsState, stock_quantity:e.target.value})
                        
                    }}
                  />
                  <button onClick={(e)=> {
                    let stock_quantity = initalDetailsState.stock_quantity;
                    //setDetailsInitalStates({...initalDetailsState, stock_quantity: stock_quantity !== 0 ? stock_quantity : initalDetailsState.stock_quantity+1});
                    //stock_quantity = initalDetailsState.stock_quantity;
                    setDetailsInitalStates({...initalDetailsState, stock_quantity: initalDetailsState.stock_quantity < 10 ? initalDetailsState.stock_quantity+1 : initalDetailsState.stock_quantity, amount: amount*(stock_quantity+1)})
                  }} class="btn btn-primary">+</button> */}
                  {
                  initalState.content_details !== null ? 
                  initalState.content_details.length > 0 && activeContentDetails.length > 0 ? 

                  activeContentDetails.sort((a,b)=> a.sort_order - b.sort_order)
                  .map((val, index)=>{
                  return(
                    index % 2 === 1 ?
      
                  val.type === "phone_number" && val.is_active ? 
                  <>
                  <div>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    style={ contentState.is_dark_mode ? { fontSize: "18px",  marginTop:"12px", color:"#707070", fontWeight:"700", whiteSpace:"pre-line", position:"relative"}:{fontSize: "18px",  marginTop:"12px", fontWeight:"700", color:"rgba(0,0,0,0.75)", whiteSpace:"pre-line", position:"relative"}}
                  >
                    {val.title} <span style={{ color: "red" }}> {val.is_mandatory ? "*" : ""} </span>
                    
                    { val.tooltip !== "" ?
                    (<span style={{ marginTop: "5px", marginLeft:"2px"}}>
                      <Tooltip title={val.tooltip}>
                          {/* <img src={TooltipImg} alt={"info icon"} width="15px"/>  */}
                          <span>
                          <TooltipImage color = {contentState.is_dark_mode ? "#707070" : "#fff"} />
                          </span>
                      </Tooltip>
                    </span>)
                    : ""
                   }
                  
                  </InputLabel>
                  <PhoneInput
                    id={val.key}
                    inputProps={{ id: val.key}}
                    country={phonelanguage}
                    name="phoneNumber"
                    placeholder="Phone Number"
                    countryCodeEditable={false}
                    // value={formatPhoneNumber(initalState.phoneNumber)}
                    preferredCountries={["vn", "th", "sg", "in", "ph", "id", "my"]}
                    autoFormat={true}
                    inputStyle={contentState.is_dark_mode ? {
                      height: "42px",
                      width: "100%",
                      fontSize:"13px",
                      fontWeight: "500",
                      border:"1px solid #FFFFFF1A",
                      backgroundColor: "transparent",
                      color:"#ffffff"
                    } :{
                      height: "42px",
                      width: "100%",
                      fontSize:"13px",
                      fontWeight: "500",
                      border:"1px solid #0024644D",

                      backgroundColor: "transparent",
                    }}
                    // countryCodeEditable={false}
                    //   inputProps={{
                    //     value: `${initalState.phoneNumber}`,
                    //   }}
                    onChange={(value, data, event, formattedValue) => handleChange(event)}
                    // onChange={(value, data, event, formattedValue) => {
                    //   if (value.length < 10) {
                    //     setErrors({ ...errors, phoneNumber: true });
                    //     //setDisable(true);
                    //   } 
                    //   else{
                    //     //setDisable(false);
                    //     setErrors({ ...errors, phoneNumber: false });
                    //   }
                    //   setInitalStates({
                    //     ...initalState,
                    //     phoneNumber: `${value}`,
                    //   });
                      
                    //}}
                  />
                  </div>
                  <FormHelperText style={{ color: "red", fontSize: "12px" }}>
                    {errors.content_details[index].value && "Invalid Phone Number"}
                  </FormHelperText>
                  </>
                  :
                  val.drop_down_options && (val.drop_down_options.options !== null && val.is_active  ? 
                  val.drop_down_options.options.length > 0 && val.type === "dropdown" ?
                  
                    <FormControl size="small" fullWidth variant="standard" sx={{ margin: "12px 0px 0px 0px"}}>
                      <div>
                      <InputLabel shrink htmlFor="bootstrap-input" style={ contentState.is_dark_mode ? { fontSize: "18px",  color:"#707070", fontWeight:"700", whiteSpace:"pre-line", position:"relative"}:{fontSize: "18px",  fontWeight:"700", color:"rgba(0,0,0,0.75)", whiteSpace:"pre-line", position:"relative"}}>
                      {val.title} <span style={{ color: "red" }}> {val.is_mandatory ? "*" : ""} </span> 
                      {val.tooltip !== "" ? 
                        (<span style={{ marginTop: "5px", marginLeft:"2px"}}>
                          <Tooltip title={val.tooltip}>
                            {/* <img src={TooltipImg} alt={"info icon"} width="15px" />  */}
                            <span>
                            <TooltipImage color = {contentState.is_dark_mode ? "#707070" : "#fff"} />
                            </span>
                        </Tooltip>
                        </span>)
                        : ""
                      }
                      </InputLabel>
                      </div>
                      <Select
                        style={contentState.is_dark_mode ? { height: "42px", borderRadius:"5px", color:"#ffffff", border:"1px solid #FFFFFF1A" } : {height: "42px", border:"1px solid #0024644D", borderRadius:"5px"}}
                        labelId="demo-simple-select-required-label"
                        id={val.key}
                        name={val.key}
                        required={val.is_mandatory}
                        value={val.value}
                        label={val.display_title }
                        onChange={(value) => handleChange(value, "dropdown", val.key)}
                        input={<BootstrapInput />
                         }
                         sx={contentState.is_dark_mode ? {'.MuiSvgIcon-root':{ width:"20px", height:"20px", color:"#FFFFFF1A"}, '& .MuiInputBase-input': { "&:focus": { boxShadow:"none"}}}: {'& .MuiInputBase-input': { "&:focus": { boxShadow:"none"}}}}
                      >
                        <MenuItem value="">-----------</MenuItem>
                        {val.drop_down_options.options.map((option) => 
                        <MenuItem value={option.value}>{option.name}</MenuItem>
                        )}
                        
                
                      </Select>
                    </FormControl>
                    
                  :
                  val.type==="datepicker" && val.is_active ? 
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    isdark={contentState.is_dark_mode}
                    //placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    //onFocus="black"
                    value={val.value === "" ? null : val.value }
                    onChange={(value) => {
                      value===null ? 
                      handleChange("", "datepicker", val.key)
                      :
                      handleChange(value, "datepicker", val.key)
                    }}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid date"
                    }
                  />
                  :
                  val.type==="email" && val.is_active ? 
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    isdark={contentState.is_dark_mode}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    //onFocus="black"
                    value={val.value}
                    onChange={handleChange}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid Email"
                    }
                  />
                  : 
                  val.is_active ?
                  val.type !== "checkbox" ?
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    isdark={contentState.is_dark_mode}
                    //onFocus="black"
                    value={val.value}
                    onChange={handleChange}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid "+`${val.title}`
                    }
                  />
                  :
                    <div style={{  display: "flex", alignItems:"center", marginTop:"30px", marginBottom:"30px" }}>
                      <Checkbox checked={val.value} value={val.value} onChange={(e) => handleChange(e, "checkbox", val.key)} />
                      <InputLabel htmlFor="bootstrap-input" style={ contentState.is_dark_mode ? {  color:"#707070", fontSize:"16px", textOverflow:"initial", whiteSpace:"normal"}: { fontSize:"16px", color:"rgba(0,0,0,0.75)", textOverflow:"initial", whiteSpace:"normal"}}>{val.description}
                        <span style={val.is_mandatory ? { color: "red" }: contentState.is_dark_mode ? {color:"transparent"} : {color: "transparent"}}> {"*"} </span>
                        { val.tooltip !== "" ?
                          (<span style={{ marginLeft:"2px",  marginTop:"2px"}}>
                            <Tooltip title={val.tooltip}>
                                <span>
                                <TooltipImage color = {contentState.is_dark_mode ? "#707070" : "#fff"} size = "12" />
                                </span>
                            </Tooltip>
                          </span>)
                          : ""
                        }
                      </InputLabel>
                    </div>
                  :""
                  : 
                  val.is_active ?
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    isdark={contentState.is_dark_mode}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    //onFocus="black"
                    value={val.value}
                    onChange={handleChange}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid "+`${val.title}`
                    }
                  />
                  :""
                  )
                  
                  :"")
                
                  })
                
                  : ""
                  : ""
                  }
                  
                  
                </Grid>
                <Grid item xs={12} sm={6}>
                
                  {
                  initalState.content_details !== null ? 
                  initalState.content_details.length > 0 && activeContentDetails.length > 0 ? 

                  activeContentDetails.sort((a,b)=> a.sort_order - b.sort_order)
                  .map((val, index)=>{
                  return(
                    index % 2 === 0 ?
      
                  val.type === "phone_number" && val.is_active ? 
                  <>
                  <div>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    style={ contentState.is_dark_mode ? { fontSize: "18px",  marginTop:"12px", color:"#707070", fontWeight:"700", whiteSpace:"pre-line", position:"relative"}:{fontSize: "18px",  marginTop:"12px",  fontWeight:"700", color:"rgba(0,0,0,0.75)", whiteSpace:"pre-line", position:"relative"}}
                  >
                    {val.title} <span style={{ color: "red" }}> {val.is_mandatory ? "*" : ""} </span>
                    
                    { val.tooltip !== "" ?
                    <span style={{ marginTop: "5px", marginLeft:"2px"}}>
                      <Tooltip title={val.tooltip}>
                          {/* <img src={TooltipImg} alt={"info icon"} width="15px"/>  */}
                          <span>
                          <TooltipImage color = {contentState.is_dark_mode ? "#707070" : "#fff"} />
                          </span>
                      </Tooltip>
                    </span>
                    : ""
                   }
                  
                  </InputLabel>
                  <PhoneInput
                    id={val.key}
                    inputProps={{ id: val.key}}
                    country={phonelanguage}
                    name="phoneNumber"
                    placeholder="Phone Number"
                    countryCodeEditable={false}
                    // value={formatPhoneNumber(initalState.phoneNumber)}
                    preferredCountries={["vn", "th", "sg", "in", "ph", "id", "my"]}
                    autoFormat={true}
                    inputStyle={contentState.is_dark_mode ? {
                      height: "42px",
                      width: "100%",
                      fontSize:"13px",
                      fontWeight: "500",
                      border:"1px solid #FFFFFF1A",
                      backgroundColor: "transparent",
                      color:"#ffffff"
                    } :{
                      height: "42px",
                      width: "100%",
                      fontSize:"13px",
                      fontWeight: "500",
                      border:"1px solid #0024644D",

                      backgroundColor: "transparent",
                    }}
                    // countryCodeEditable={false}
                    //   inputProps={{
                    //     value: `${initalState.phoneNumber}`,
                    //   }}
                    onChange={(value, data, event, formattedValue) => handleChange(event)}
                    // onChange={(value, data, event, formattedValue) => {
                    //   if (value.length < 10) {
                    //     setErrors({ ...errors, phoneNumber: true });
                    //     //setDisable(true);
                    //   } 
                    //   else{
                    //     //setDisable(false);
                    //     setErrors({ ...errors, phoneNumber: false });
                    //   }
                    //   setInitalStates({
                    //     ...initalState,
                    //     phoneNumber: `${value}`,
                    //   });
                      
                    //}}
                  />
                  </div>
                  <FormHelperText style={{ color: "red", fontSize: "12px" }}>
                    {errors.content_details[index].value && "Invalid Phone Number"}
                  </FormHelperText>
                  </>
                  :
                  val.drop_down_options && (val.drop_down_options.options !== null && val.is_active  ? 
                  val.drop_down_options.options.length > 0 && val.type === "dropdown" ?
                  
                    <FormControl size="small" fullWidth variant="standard" sx={{ margin: "12px 0px 0px 0px"}}>
                      <div>
                      <InputLabel shrink htmlFor="bootstrap-input" style={ contentState.is_dark_mode ? { fontSize: "18px",  color:"#707070", fontWeight:"700", whiteSpace:"pre-line", position:"relative"}:{fontSize: "18px",  fontWeight:"700", color:"rgba(0,0,0,0.75)", whiteSpace:"pre-line", position:"relative"}}>
                      {val.title} <span style={{ color: "red" }}> {val.is_mandatory ? "*" : ""} </span> 
                      {val.tooltip !== "" ? 
                        <span style={{ marginTop: "5px", marginLeft:"2px"}}>
                          <Tooltip title={val.tooltip}>
                            {/* <img src={TooltipImg} alt={"info icon"} width="15px" />  */}
                            <span>
                            <TooltipImage color = {contentState.is_dark_mode ? "#707070" : "#fff"} />
                            </span>
                        </Tooltip>
                        </span>
                        : ""
                      }
                      </InputLabel>
                      </div>
                      <Select
                        style={contentState.is_dark_mode ? { height: "42px", borderRadius:"5px", color:"#ffffff", border:"1px solid #FFFFFF1A" } : {height: "42px", border:"1px solid #0024644D", borderRadius:"5px"}}
                        labelId="demo-simple-select-required-label"
                        id={val.key}
                        name={val.key}
                        required={val.is_mandatory}
                        value={val.value}
                        label={val.display_title }
                        onChange={(value) => handleChange(value, "dropdown", val.key)}
                        input={<BootstrapInput />
                         }
                         sx={contentState.is_dark_mode ? {'.MuiSvgIcon-root':{ width:"20px", height:"20px", color:"#FFFFFF1A"}, '& .MuiInputBase-input': { "&:focus": { boxShadow:"none"}}}: {'& .MuiInputBase-input': { "&:focus": { boxShadow:"none"}}}}
                      >
                        <MenuItem value="">-----------</MenuItem>
                        {val.drop_down_options.options.map((option) => 
                        <MenuItem value={option.value}>{option.name}</MenuItem>
                        )}
                        
                
                      </Select>
                    </FormControl>
                    
                  :
                  val.type==="datepicker" && val.is_active ? 
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    isdark={contentState.is_dark_mode}
                    //placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    //onFocus="black"
                    value={val.value === "" ? null : val.value }
                    onChange={(value) => {
                      value===null ? 
                      handleChange("", "datepicker", val.key)
                      :
                      handleChange(value, "datepicker", val.key)
                    }}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid date"
                    }
                  />
                  :
                  val.type==="email" && val.is_active ? 
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    isdark={contentState.is_dark_mode}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    //onFocus="black"
                    value={val.value}
                    onChange={handleChange}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid Email"
                    }
                  />
                  : 
                  val.is_active ?
                   val.type !== "checkbox" ?
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    isdark={contentState.is_dark_mode}
                    //onFocus="black"
                    value={val.value}
                    onChange={handleChange}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid "+`${val.title}`
                    }
                  />
                  :
                    <div style={{  display: "flex", alignItems:"center", marginTop:"30px", marginBottom:"30px" }}>
                      <Checkbox checked={val.value} value={val.value} onChange={(e) => handleChange(e, "checkbox", val.key)} />
                      <InputLabel htmlFor="bootstrap-input" style={ contentState.is_dark_mode ? {  color:"#707070", fontSize:"16px", textOverflow:"initial", whiteSpace:"normal"}: { fontSize:"16px", color:"rgba(0,0,0,0.75)", textOverflow:"initial", whiteSpace:"normal"}}> {val.description}
                       <span style={val.is_mandatory ? { color: "red" } : contentState.is_dark_mode ? {color:"transparent"} : {color: "transparent"}}> {"*"} </span> 
                       { val.tooltip !== "" ?
                        (<span style={{ marginLeft:"2px", marginTop:"2px"}}>
                          <Tooltip title={val.tooltip}>
                              <span>
                              <TooltipImage color = {contentState.is_dark_mode ? "#707070" : "#fff"} size = "12" />
                              </span>
                          </Tooltip>
                        </span>)
                        : ""
                      }
                       </InputLabel>
                    </div>
                  :""
                  : 
                  val.is_active ?
                  <CustomInput
                    id={val.key}
                    variant="outlined"
                    type={val.type}
                    name={val.key}
                    placeholder={val.description}
                    label={val.title}
                    maxLength={256}
                    isdark={contentState.is_dark_mode}
                    required={val.is_mandatory}
                    tooltip={val.tooltip}
                    //onFocus="black"
                    value={val.value}
                    onChange={handleChange}
                    error={errors.content_details[index].value}
                    errorText={
                      initalState.content_details[index].value === ""
                        ? "Required"
                        : "Invalid "+`${val.title}`
                    }
                  />
                  :""
                  )
                  : ""
                  
                  )
                
                  })
                
                  : ""
                  : ""
                  }
                  {/* <CustomInput
                    id="1"
                    variant="outlined"
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    label="Full Name"
                    maxLength={256}
                    required={true}
                    onFocus="black"
                    value={initalState.fullName}
                    onChange={handleChange}
                    error={errors.fullName}
                    errorText={
                      initalState.fullName === ""
                        ? "Required"
                        : "Invalid Name"
                    }
                  /> */}
                  {/* <CustomInput
                    id="1"
                    variant="outlined"
                    placeholder="Email"
                    type="email"
                    name="email"
                    label="Email"
                    maxLength={256}
                    required={true}
                    value={initalState.email}
                    onChange={handleChange}
                    error={errors.email}
                    errorText={
                      initalState.email === "" ? "Required" : "Enter Valid email address"
                    }
                  /> */}

                  {/* <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    style={{ fontSize: "1.3rem" }}
                  >
                    Phone Number <span style={{ color: "red" }}> {"*"} </span>
                  </InputLabel>
                  <PhoneInput
                    
                    country={phonelanguage}
                    name="phoneNumber"
                    placeholder="Phone Number"
                    countryCodeEditable={false}
                    // value={formatPhoneNumber(initalState.phoneNumber)}
                    onlyCountries={["vn", "th", "sg", "in", "ph", "id"]}
                    autoFormat={true}
                    inputStyle={{
                      height: "2.8rem",
                      width: "100%",
                      fontSize:"14px",
                      fontWeight: "500",
                      //backgroundColor: "#fffff",
                    }}
                    // countryCodeEditable={false}
                    //   inputProps={{
                    //     value: `${initalState.phoneNumber}`,
                    //   }}
                    //   onChange={handleChange}
                    onChange={(value, data, event, formattedValue) => {
                      if (value.length < 10) {
                        setErrors({ ...errors, phoneNumber: true });
                        setDisable(true);
                      } 
                      else{
                        //setDisable(false);
                        setErrors({ ...errors, phoneNumber: false });
                      }
                      setInitalStates({
                        ...initalState,
                        phoneNumber: `${value}`,
                      });
                    }}
                  />
                  <FormHelperText style={{ color: "red", fontSize: "12px" }}>
                    {errors["phoneNumber"] && "Invalid Phone Number"}
                  </FormHelperText> */}
                  
                  </Grid>
                </Grid>
                :""}
                

                  <ButtonContainer
                    style={window.innerWidth > 767 ? { textAlign: "left", margin: "2rem 0 1rem 0" } : { textAlign: "left", margin: "4rem 0 1rem 0" }}
                  >
                    <SecureDiv>
                      <SecureImage src={SecurePayment} alt="securePayment" />
                      <span id="safesecurelang">
                        {t("Secure Payments as per PCI-DSS standards")}{" "}
                      </span>
                    </SecureDiv>
                    <CustomButton
                      text={` ${contentState && contentState.fixed_fields && contentState.fixed_fields.pay_button_text ? contentState.fixed_fields.pay_button_text : "PAY NOW"} ${
                        initalDetailsState.amount
                          ? 
                          flagA === true && itemQuantity !== 0 && contentState.fixed_fields.amount_field_type === "FIXED_WITH_QUANTITY" ?
                          initalDetailsState.amount &&
                            `@ ${getFormattedNumber(
                              initalDetailsState.amount*itemQuantity,
                              initalDetailsState.currency
                            )}`
                            :
                            contentState.fixed_fields.amount_field_type === "CUSTOM_USER_INPUT" ? 
                              disable === false ?
                              `@ ${getFormattedNumber(
                                initalDetailsState.amount,
                                initalDetailsState.currency
                              )}`
                              :
                              ""
                            :
                              initalDetailsState.amount &&
                              `@ ${getFormattedNumber(
                                initalDetailsState.amount,
                                initalDetailsState.currency
                              )}`
                          : ""
                      }`}
                      variant="contained"
                      contentState={contentState}
                      disabled={disable}
                      onClick={handlePay}
                    />
                  </ButtonContainer>
                  {/* <div style={{ textAlign: "center" }}>
                    <CardsImage src={CardsIcon} alt="cardsicon" />
                  </div> */}
                  
                  
                
                </FormDiv>
               
                
                <div className="ChildCardDiv">
                    </div>
              </div>
            { window.innerWidth <= 992 &&
              <div className={classes.supportContainer} style={ contentState &&
                contentState.custom_fields && activeContentDetails.length >5  ? window.innerWidth <= 768 ? { marginTop: dynamicMargin*9+"rem" }:{ marginTop: dynamicMargin*9+"rem" } : contentState && contentState.custom_fields && activeContentDetails.length <= 3 ?{marginTop: 12 + dynamicMargin*12+"rem"}:{ marginTop: dynamicMargin*12+"rem" }}>
              {contentState &&
              contentState.merchant_details &&
              contentState.merchant_details.phone_number ? (
                <Typography
                  variant="h5"
                  component={"h5"}
                  style={ contentState.is_dark_mode ?  { marginTop: "20px", padding:"9px 0", fontSize:"15px", color:"#F7F4F4" } :{marginTop: "20px", padding:"9px 0", fontSize:"15px"}}
                >
                  {t("Contact Us")}
                </Typography>
              ) : contentState &&
                contentState.merchant_details &&
                contentState.merchant_details.email_address ? (
                <Typography
                  variant="h5"
                  component={"h5"}
                  style={ contentState.is_dark_mode ?  { marginTop: "20px", padding:"9px 0", color:"#F7F4F4" }: {marginTop: "20px", padding:"9px 0"}}
                >
                  {t("Contact Us")}
                </Typography>
              ) : (
                ""
              )}
                        
              <Typography variant="h6" component={"h6"} style={{ marginBottom:"20px"}}>
                <div style={ contentState.is_dark_mode ? { display: "flex", alignItems: "center", color:"#707070"} : {display: "flex", alignItems: "center"}}>
                {contentState &&
                contentState.merchant_details &&
                contentState.merchant_details.phone_number ? 
                // <img src={Phone} alt="phoneicon" style={{ marginRight: "5px", color: `${
                //   contentState &&
                //   contentState.merchant_details &&
                //   contentState.merchant_details.brand_primary_color
                //     ? contentState.merchant_details.brand_primary_color
                //     : "#000"
                // }` }} />
                  <PhoneImage isdark = {contentState.is_dark_mode} color1 = {`${
                    contentState &&
                    contentState.merchant_details &&
                    contentState.merchant_details.brand_primary_color
                      ? contentState.merchant_details.brand_primary_color
                      : "#000"
                  }`} />
                :
                  ""
                }{" "}
                {contentState &&
                  contentState.merchant_details &&
                  contentState.merchant_details.phone_number}
                </div>
                <Typography variant="h6" component={"h6"} style={{ marginTop: "8px", display:"flex", alignItems:"center" }}>
                  {contentState &&
                  contentState.merchant_details &&
                  contentState.merchant_details.email_address ? 
                    // <img src={Mail} alt="mailicon" style={{ marginRight: "3px", color: `${
                    //   props.contentState &&
                    //   props.contentState.merchant_details &&
                    //   props.contentState.merchant_details.brand_primary_color
                    //     ? props.contentState.merchant_details.brand_primary_color
                    //     : "#000"
                    // }` }} />
                    <MailImage isdark = {contentState.is_dark_mode}
                    color1={`${
                        contentState &&
                        contentState.merchant_details &&
                        contentState.merchant_details.brand_primary_color
                          ? contentState.merchant_details.brand_primary_color
                          : "#000"
                      }` } />
                  : 
                    ""
                  }{" "}
                  {contentState &&
                    contentState.merchant_details &&
                    contentState.merchant_details.email_address}
                </Typography>
              </Typography>
              {contentState &&
                contentState.merchant_details &&
                (contentState.merchant_details.phone_number || contentState.merchant_details.email_address) ?
              <span> <hr style={{ opacity:"0.2", borderTop: "dashed 1px" }}/></span> : <span></span>}
              <Typography
                variant="h5"
                component="h5"
                style={ contentState.is_dark_mode ?  { marginTop: "20px", marginBottom: "20px", fontSize:"15px", color:"#F7F4F4" }: {marginTop: "20px", marginBottom: "20px", fontSize:"15px"}}
              >
                {t("Terms And Conditions")}
              </Typography>
              <Typography variant="h6" component={"h6"} style={contentState.is_dark_mode ?  { color:"#707070"} : {}}>
                {parse(`${contentState.terms_and_conditions}`)}
              </Typography>

              {contentState.link_description ? <span> <hr style={{ opacity:"0.2", borderTop: "dashed 1px" }}/></span> : <span></span>}

              <Typography style={{ marginTop: "20px" }}>
              <div style={contentState.is_dark_mode ? { color:"#707070", display:"flex", alignItems:"center", fontSize:"small", marginBottom:"8px" }:{display:"flex", alignItems:"center", fontSize:"small", marginBottom:"8px"}}>
              Powered by
              {contentState.is_dark_mode ?
                <ChaiImage src={ChaiDarkIcon} alt="chaiicon" /> :
                <ChaiImage src={ChaiLightIcon} alt="chaiicon" />}
                </div>
              </Typography>
              <Typography variant="h6" component={"h6"} style={ contentState.is_dark_mode ?  { color:"#707070"} :{}}>
                {t("Want to create page like this for your Business?")}
                <a
                  href="https://docs.portone.cloud/docs"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="p"
                    component={"span"}
                    style={{ color: "#1492E6" }}
                  >
                    {" "}
                    {t("Payment pages docs")}{" "}
                  </Typography>
                </a>
                {t("to get started!")}
              </Typography>
              {/* <Typography variant="p" component="p" style={{ marginTop: "10px" }}>
                <u>Report Page</u>
              </Typography> */}
            </div>
          }

                
                
                
                
              </Box>
              {/* </BackgroundImage> */}
            </Grid>
          </Grid>
        </MainContainer>
        </div>
      </div>
    }
    </div>
    </>
  );
};

export default Documents;